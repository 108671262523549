import React from 'react'

interface FormErrorsProps {
  errors: string[]
}

const FormErrors: React.FC<FormErrorsProps> = ({errors}) => {
  return (
    <>
      {errors.length > 0 && (
        <ul className="form_error_message">
          {errors.map((error, idx) => {
            return <li key={idx}>{error}</li>
          })}
        </ul>
      )}
    </>
  )
}

export default FormErrors
