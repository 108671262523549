import axios from 'axios'

const setToken = (params: any) => {
  params.authenticity_token = document.getElementsByName('csrf-token')[0].getAttribute('content')
  return params
}

const api = axios.create({
  baseURL: '/api/public/users',
  headers: {'Content-Type': 'application/json'},
  responseType: 'json'
})

export default {
  get: (path: string, params = {}) => {
    return api.get(path, {params})
  },
  post: (path: string, params = {}) => {
    return api.post(path, setToken(params))
  }
}
