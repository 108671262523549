import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'

Rails.start()
ActiveStorage.start()

// 以下、react-rails用の初期化処理
// Support component names relative to this directory:
var componentRequireContext = require.context('packs', true)
var ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)

// 共通のcssを読み込んでおく。
// ここで読み込んでおかないと、本番環境でstylesheet_pack_tagでファイルが読み込まれない
// なお、railsの挙動がわかりにくいので補足しておくが、
// development環境では,application.jsが読み込まれれば、stylesheet_pack_tagがなくても動的に読み込まれる。
import '../stylesheets/application.scss'
