import React, {useState} from 'react'
import api from '@/modules/publicApiClient'
import FormErrors from '@/apps/shared/FormErrors'
import {useTranslation} from 'react-i18next'
import {INFORMATION_SECURITY_URL, PRIVACY_POLICY_URL} from '@/constants/Urls'

enum States {
  SendEmail,
  ConfirmPin,
  RegisterUser,
  Complete
}

const Users = (): JSX.Element => {
  const {t} = useTranslation()

  const [currentStep, setCurrentStep] = useState(States.SendEmail)
  const [failureMessages, setFailureMessages] = useState([])

  const [email, setEmail] = useState('')
  const [pin, setPin] = useState('')
  const [password, setPassword] = useState('')
  const [confirmationPassword, setConfirmationPassword] = useState('')
  const [agree, setAgree] = useState(false)
  const [returnUrl, setReturnUrl] = useState('')

  const onSubmitSendMail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setFailureMessages([])

    const sendEmail = async (email: {email: string}) => {
      try {
        return await api.post('/validate_email', {email})
      } catch (e) {
        return e.response
      }
    }

    try {
      const response = await sendEmail({email})

      if (response.data?.error_details) {
        setFailureMessages(response.data.error_details)
        return
      }
    } catch (e) {
      setFailureMessages([t('create_user.messages.error')])
      return
    }

    setCurrentStep(States.ConfirmPin)
  }

  const onClickEmailDidNotReach = () => {
    setPin('')
    setFailureMessages([])
    setCurrentStep(States.SendEmail)
  }

  const onSubmitConfirmPin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setFailureMessages([])

    const confirmPin = async (pin: {pin: string}) => {
      try {
        return await api.get('/validate_pin', pin)
      } catch (e) {
        return e.response
      }
    }

    try {
      const response = await confirmPin({pin})

      if (response.data?.error_details) {
        setFailureMessages(response.data.error_details)
        return
      }

      if (response.data?.result) {
        setCurrentStep(States.RegisterUser)
      } else {
        setFailureMessages([t('create_user.messages.invalid_pin')])
      }
    } catch {
      setFailureMessages(t('create_user.messages.error'))
      return
    }
  }

  const onSubmitCreateAccount = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setFailureMessages([])

    const SPECIAL_CHARS = ['!', '#', '%', '&', ':', '@', '~']
    if (!SPECIAL_CHARS.some((s) => password.includes(s))) {
      setFailureMessages([t('create_user.messages.need_special_char')])
      return
    }

    if (!/\d+/.test(password)) {
      setFailureMessages([t('create_user.messages.need_number')])
      return
    }

    if (!/[a-z]+/.test(password)) {
      setFailureMessages([t('create_user.messages.need_lowercase')])
      return
    }

    if (password !== confirmationPassword) {
      setFailureMessages([t('create_user.messages.password_does_not_match')])
      return
    }

    if (!agree) {
      setFailureMessages([t('create_user.messages.need_agreement')])
      return
    }

    const createUser = async (user: {pin: string; password: string; agree: string}) => {
      try {
        return await api.post('/create_user', {user})
      } catch (e) {
        return e.response
      }
    }

    try {
      const response = await createUser({pin: pin, password: password, agree: agree ? '1' : '0'})
      if (response.data?.error_details) {
        setFailureMessages(response.data.error_details)
        return
      }

      setReturnUrl(response.data.result)
      setCurrentStep(States.Complete)
    } catch (e) {
      setFailureMessages([t('create_user.messages.error')])
      return
    }
  }

  return (
    <div className="content-wrap">
      <div className="form-wrap">
        <div className="service-name">
          <img src="/img/logomark_square.svg" width="150px" />
        </div>

        <div className="service-name">
          <h1>{t('create_user.title')}</h1>
        </div>

        <div className="input-area">
          {currentStep === States.SendEmail && (
            <>
              <form onSubmit={onSubmitSendMail} acceptCharset="UTF-8">
                <FormErrors errors={failureMessages} />

                <div className="field">
                  <div style={{display: 'flex', marginBottom: '35px'}}>
                    <div>{t('create_user.guide.send_mail')}</div>
                  </div>

                  <input
                    autoComplete="email"
                    type="email"
                    placeholder={t('create_user.labels.email_address')}
                    value={email}
                    required
                    onInput={(e) => setEmail(e.currentTarget.value)}
                  />
                </div>
                <div className="actions">
                  <button type="submit">{t('create_user.events.send_mail')}</button>
                </div>
              </form>
            </>
          )}

          {currentStep === States.ConfirmPin && (
            <>
              <form onSubmit={onSubmitConfirmPin} acceptCharset="UTF-8">
                <FormErrors errors={failureMessages} />

                <div className="field">
                  <div style={{display: 'flex', marginBottom: '35px'}}>
                    <div style={{whiteSpace: 'pre-wrap'}}>{t('create_user.guide.confirm_pin')}</div>
                  </div>

                  <input
                    style={{width: '50px'}}
                    type="text"
                    placeholder=""
                    maxLength={4}
                    value={pin}
                    required
                    onInput={(e) => setPin(e.currentTarget.value)}
                  />
                </div>
                <div className="actions">
                  <button>{t('create_user.events.confirm_pin')}</button>
                </div>

                <div style={{marginTop: '20px', textAlign: 'center'}}>
                  <a onClick={onClickEmailDidNotReach}>{t('create_user.events.did_not_reach_email')}</a>
                </div>
              </form>
            </>
          )}

          {currentStep === States.RegisterUser && (
            <>
              <form onSubmit={onSubmitCreateAccount} acceptCharset="UTF-8">
                <FormErrors errors={failureMessages} />

                <div className="field">
                  <div style={{display: 'flex', marginBottom: '35px'}}>
                    <div>{t('create_user.guide.create_user')}</div>
                  </div>

                  <input
                    type="password"
                    placeholder={t('create_user.labels.password')}
                    value={password}
                    minLength={8}
                    required
                    onInput={(e) => setPassword(e.currentTarget.value)}
                  />
                  <input
                    type="password"
                    placeholder={t('create_user.labels.confirmation_password')}
                    value={confirmationPassword}
                    minLength={8}
                    required
                    onInput={(e) => setConfirmationPassword(e.currentTarget.value)}
                  />
                  <span
                    style={{
                      whiteSpace: 'pre-wrap',
                      marginTop: '-10px',
                      marginLeft: '5px'
                    }}
                  >
                    {t('create_user.guide.password_policy')}
                  </span>

                  <div style={{marginLeft: '10px', marginTop: '30px', marginBottom: '24px'}}>
                    <a href={PRIVACY_POLICY_URL} target={'_blank'} rel="noreferrer">
                      {t('create_user.labels.privacy_policy')}
                    </a>
                    <br />
                    <a href={INFORMATION_SECURITY_URL} target={'_blank'} rel="noreferrer">
                      {t('create_user.labels.information_security')}
                    </a>
                  </div>

                  <label>
                    <input type="checkbox" id={'agreeToPolicies'} onInput={(e) => setAgree(e.currentTarget.checked)} />
                    {t('create_user.labels.agree')}
                  </label>
                </div>
                <div className="actions">
                  <button>{t('create_user.events.create_user')}</button>
                </div>
              </form>
            </>
          )}

          {currentStep === States.Complete && (
            <>
              <div className="service-name">
                <h1>{t('create_user.guide.complete')}</h1>
              </div>

              <div>
                <a href={returnUrl}>
                  <button>{t('create_user.events.back')}</button>
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Users
