import React, {useCallback, useState, useEffect} from 'react'
import api, {Failure} from '@/modules/apiClient'
import {useTranslation} from 'react-i18next'
import {changeLanguage} from '@users/features/changeLanguage/changeLanguageAPI'
import GlobalMessages from '@/apps/shared/GlobalMessages'
import FormErrors from '@/apps/shared/FormErrors'

const ChangeLanguage: React.VFC = () => {
  const [t, i18n] = useTranslation()
  const [language, setLanguage] = useState('')
  const [languageId, setLanguageId] = useState('')
  const [languages, setLanguages] = useState([])
  const [success_message, setSuccessMessage] = useState('')
  const [failure_messages, setFailureMessages] = useState([])

  // 初期化
  useEffect(() => {
    // プルダウン用の言語マスタ
    const fetch = async () => {
      const result = await api.get('/languages')
      setLanguages(result.data)
    }
    fetch()

    // ユーザの言語を取得
    const fetchLanguage = async () => {
      const result = await api.get('/language')
      setLanguage(result.data.code)
      setLanguageId(result.data.id)
    }
    fetchLanguage()

    i18n.changeLanguage(language)
  }, [language])

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const response = await changeLanguage(languageId)
      let result
      if (response.status >= 400) {
        result = response.data as Failure
        setFailureMessages(result.error_details)
        resetSuccessMessage()
      } else {
        result = response.data
        const language = result.response.optional_response.language
        setLanguage(language)
        i18n.changeLanguage(language)
        setSuccessMessage(t('change_language.success'))
      }
    },
    [languageId]
  )

  const updateLanguageId = (e: React.FormEvent<HTMLSelectElement>) => {
    setLanguageId(e.currentTarget.value)
  }

  const resetSuccessMessage = () => {
    setSuccessMessage('')
  }

  return (
    <div className="content-wrap">
      {success_message && <GlobalMessages messages={[success_message]} />}
      <div className="form-wrap">
        <div className="service-name">
          <span>{t('change_language.title')}</span>
        </div>
        <div className="input-area">
          <form onSubmit={handleSubmit}>
            <FormErrors errors={failure_messages} />
            <div className="field">
              <select value={languageId} onChange={updateLanguageId}>
                {languages.map((language, idx) => (
                  <option key={idx} value={language.id}>
                    {language.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="actions">
              <button type="submit">
                {t('change_language.submit')}
                <i className="fas fa-arrow-alt-circle-right fa-lg fa-pull-right"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ChangeLanguage
