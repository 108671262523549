import axios from 'axios'

const setToken = (params: any) => {
  params.authenticity_token = document.getElementsByName('csrf-token')[0].getAttribute('content')
  return params
}

const api = axios.create({
  baseURL: '/api/internal/users',
  headers: {'Content-Type': 'application/json'},
  responseType: 'json'
})

export type Success = {
  result: 'SUCCESS'
  status: 200
}

export type Failure = {
  result: 'FAILURE'
  status: 400
  error_title: string
  error_details: string[]
}

export type Result = Success | Failure

export default {
  get: (path: string, params = {}) => {
    return api.get(path, {params})
  },
  post: (path: string, params = {}) => {
    return api.post(path, setToken(params))
  },
  put: (path: string, params = {}) => {
    return api.put(path, setToken(params))
  },
  patch: (path: string, params = {}) => {
    return api.patch(path, setToken(params))
  },
  delete: (path: string, params = {}) => {
    return api.delete(path, {params: setToken(params)})
  }
}
