import i18next, {i18n, TFunction, StringMap} from 'i18next'
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import {DetectorOptions} from 'i18next-browser-languagedetector'

class I18nUtil {
  private static instance: i18n
  private static reservedResources: any[] = []

  static initialize(resources: any, language?: string): i18n {
    if (!I18nUtil.instance) {
      I18nUtil.instance = i18next.createInstance()

      I18nUtil.instance
        .use(initReactI18next)
        .use(LanguageDetector)
        .init({
          resources,
          keySeparator: ',',
          detection: I18nUtil.getDetectionOptions(),
          lng: language, // 引数languageを直接指定した場合は、Detectorよりもlanguageが優先される。
          nsSeparator: '.',
          fallbackLng: 'ja', //フォールバック言語は、サーバー側と合わせてjaにしておく
          interpolation: {
            escapeValue: false // not needed for react!!
          }
        })
      if (I18nUtil.reservedResources.length > 0) {
        I18nUtil.reservedResources.forEach((resources) => {
          I18nUtil.appendResource(resources)
        })
      }
    }

    return I18nUtil.instance
  }
  // 言語検出の優先順位設定
  private static getDetectionOptions(): DetectorOptions {
    // https://github.com/i18next/i18next-browser-languageDetector#detector-options 参照
    return {
      // order and from where user language should be detected
      order: [
        'htmlTag', // サーバーで言語を強制指定したい場合は、<html lang="ja">のような指定をしておけば、ここで拾われる
        'navigator', // htmlTagがない場合は、ブラウザの言語設定を優先
        'querystring', // これ以降は実際は使われることがなさそうだが、保守しやすさのために残しておく
        'cookie',
        'localStorage',
        'sessionStorage',
        'path',
        'subdomain'
      ],

      // keys or params to lookup language from
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupSessionStorage: 'i18nextLng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

      // optional expire and domain for set cookie
      cookieMinutes: 10,
      cookieDomain: 'myDomain',

      // optional htmlTag with lang attribute, the default is:
      htmlTag: document.documentElement,

      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      cookieOptions: {path: '/', sameSite: 'strict'}
    }
  }

  private static getInstance(): i18n {
    if (!I18nUtil.instance) {
      throw 'I18nUtil is not initialized.'
    }
    return I18nUtil.instance
  }
  /*
   * t関数を取得する関数
   *   const t = I18nUtil.getTranslator()
   *   t('ns.key')
   * のように使う
   */
  static getTranslator(language?: string): TFunction {
    return I18nUtil.getInstance().getFixedT(language || I18nUtil.instance.language)
  }

  /*
   * t関数を直接呼び出す.
   *   I18nUtil.t('ns.key')
   * のように使う
   */
  static t(key: string, options?: StringMap): string {
    return I18nUtil.getTranslator()(key, options)
  }

  private static appendResource(resources: any) {
    Object.keys(resources).forEach((lng) => {
      Object.keys(resources[lng]).forEach((ns) => {
        I18nUtil.instance.addResourceBundle(lng, ns, resources[lng][ns], true, true)
      })
    })
  }

  static addResources(resources: any) {
    // すでにインスタンスがあればリソースを追加
    // なければ保管しておいて初期化時に投入
    if (I18nUtil.instance) {
      I18nUtil.appendResource(resources)
    } else {
      I18nUtil.reservedResources.push(resources)
    }
  }
}
export default I18nUtil
