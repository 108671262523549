import React from 'react'
import SignIn from '@/apps/sign_in/pages/SignIn'
import i18nUtil from '@/modules/i18nUtil'
import 'react-app-polyfill/ie11'

const resources = require('@alienfast/i18next-loader!@/locales/sign_in')
i18nUtil.initialize(resources)

const csrfHeader = document.querySelector('[name=csrf-token]') as HTMLElement

interface SignInAppProps {
  email?: string
}

class SignInApp extends React.Component<SignInAppProps> {
  render() {
    return (
      <React.StrictMode>
        <SignIn csrf={csrfHeader.getAttribute('content')} email={this.props.email} />
      </React.StrictMode>
    )
  }
}

export default SignInApp
