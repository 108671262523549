import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {changePassword} from '@users/features/changePassword/changePasswordAPI'
import {Result, Success, Failure} from '@/modules/apiClient'
import GlobalMessages from '@/apps/shared/GlobalMessages'
import FormErrors from '@/apps/shared/FormErrors'

const ChangePassword: React.VFC = () => {
  const {t} = useTranslation()
  const [current_password, setCurrentPassword] = useState('')
  const [new_password, setNewPassword] = useState('')
  const [confirmation_password, setConfirmationPassword] = useState('')
  const [failure_messages, setFailureMessages] = useState([])
  const [success_message, setSuccessMessage] = useState('')

  const updateCurrentPassword = (e: React.FormEvent<HTMLInputElement>) => {
    setCurrentPassword(e.currentTarget.value)
  }
  const updateNewPassword = (e: React.FormEvent<HTMLInputElement>) => {
    setNewPassword(e.currentTarget.value)
  }
  const updateConfirmationPassword = (e: React.FormEvent<HTMLInputElement>) => {
    setConfirmationPassword(e.currentTarget.value)
  }
  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const response = await changePassword({current_password, new_password, confirmation_password})
      let result: Result
      if (response.status >= 400) {
        result = response.data as Failure
        setFailureMessages(result.error_details)
        resetSuccessMessage()
      } else {
        result = response.data as Success
        setSuccessMessage(t('change_password.success'))
        clearForm()
      }
    },
    [current_password, new_password, confirmation_password, failure_messages]
  )

  const clearForm = () => {
    setFailureMessages([])
    setCurrentPassword('')
    setNewPassword('')
    setConfirmationPassword('')
  }

  const resetSuccessMessage = () => {
    setSuccessMessage('')
  }

  return (
    <div className="content-wrap">
      {success_message && <GlobalMessages messages={[success_message]} />}
      <div className="form-wrap">
        <div className="service-name">
          <span>{t('change_password.title')}</span>
        </div>
        <div className="input-area">
          <form onSubmit={handleSubmit}>
            <FormErrors errors={failure_messages} />
            <div className="field">
              <input
                autoFocus
                type="password"
                placeholder={t('change_password.current_password')}
                value={current_password}
                onInput={updateCurrentPassword}
              />
              <span>{t('change_password.description')}</span>

              <input
                type="password"
                placeholder={t('change_password.new_password')}
                value={new_password}
                onInput={updateNewPassword}
              />

              <input
                type="password"
                placeholder={t('change_password.confirmation_password')}
                value={confirmation_password}
                onInput={updateConfirmationPassword}
              />
            </div>
            <div className="actions">
              <button type="submit">
                {t('change_password.submit')}
                <i className="fas fa-arrow-alt-circle-right fa-lg fa-pull-right"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
