import React from 'react'

interface GlobalMessagesProps {
  messages?: string[]
  errors?: string[]
}

const messageExists = (messages?: string[]) => {
  return messages && messages.length > 0
}

const GlobalMessages: React.FC<GlobalMessagesProps> = ({messages, errors}) => {
  return (
    <>
      {(messageExists(messages) || messageExists(errors)) && (
        <div>
          {messageExists(messages) && (
            <ul className="global_message">
              {messages.map((message, idx) => {
                return <li key={idx}>{message}</li>
              })}
            </ul>
          )}
          {messageExists(errors) && (
            <ul className="global_error_message">
              {errors.map((error, idx) => {
                return <li key={idx}>{error}</li>
              })}
            </ul>
          )}
        </div>
      )}
    </>
  )
}

export default GlobalMessages
