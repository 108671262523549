import api from '@/modules/apiClient'

export const changePassword = async (passwords: {
  current_password: string
  new_password: string
  confirmation_password: string
}) => {
  try {
    return await api.post('/change_password', {passwords})
  } catch (err) {
    return err.response
  }
}
