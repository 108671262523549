// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import React from 'react'
import i18nUtil from '@/modules/i18nUtil'
import {BrowserRouter, Route} from 'react-router-dom'
import ChangePassword from '@users/pages/ChangePassword'
import ChangeLanguage from '@users/pages/ChangeLanguage'
import {Switch} from 'react-router'
import 'react-app-polyfill/ie11'
import Users from '@/apps/ragistration/pages/Users'

const resources = require('@alienfast/i18next-loader!@/locales/users')
i18nUtil.initialize(resources)

class UsersApp extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/users/change_password">
            <ChangePassword />
          </Route>
          <Route path="/users/change_language">
            <ChangeLanguage />
          </Route>
          <Route path="/registration/users">
            <Users />
          </Route>
        </Switch>
      </BrowserRouter>
    )
  }
}

export default UsersApp
